import styles from './Loader.module.css'
import lick3 from '../../assets/lick_3.png'
import lick2 from '../../assets/lick_2.png'
import lick1 from '../../assets/lick_1.png'
import { useEffect } from 'react'

const Loader = () => {
  useEffect(() => {
    try {
      // if(window.innerWidth < 500) return;
      const slides = document.querySelectorAll<HTMLDivElement>('#loader_update_bg > div');
      
      let delay = 0.2;
      const TIMING_CONSTANT = 1.2

      slides.forEach((slide: HTMLDivElement) => {
        slide.style.width = '0px';
        slide.style.transitionDelay = `${delay}s`;
        slide.style.transitionDuration = `${TIMING_CONSTANT}s`;
        delay += TIMING_CONSTANT;
      })
    } catch (error) {
      console.log(error)  
    }
  })

  const SLIDE_SIZE = 20

  return (
    <div className={styles.container} id='loader_container'>
        <div className={styles.update_bg} id='loader_update_bg'>
          {Array(SLIDE_SIZE).fill(0, 0).map((_, key) => (
            <SlideBox
              lickImg={(key%3 + 1 === 1) ? lick3 : (key%3 + 1 === 2) ? lick2 : lick1}
              zIndex={(SLIDE_SIZE*2 - key).toString()}
              bgId={`bg_${key%3 + 1}`}
              style = { {} }
            />
          ))}
        </div>
{/* 
        <div className={styles.handle_center_body}>
            <img src={lick} alt="" className='transition-all duration-[0.3s] ease-in-out' />

            <h1 className='transition-all duration-[0.3s] ease-in-out'>Licking...</h1>
        </div> */}
    </div>
  )
}

export default Loader;

type SlideBoxProps = {
  zIndex: string;
  bgId: string;
  lickImg: string;
  style: {}
}

const SlideBox = ({ zIndex, bgId, lickImg, style }: SlideBoxProps) => {
  return (
    <div style={{ zIndex, ...style }} className={styles[bgId]}>
      <div style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%`} : {}}>
        <img src={lickImg} alt="" />
        <h1>Licking...</h1>
      </div>
    </div>
  )
}