import { useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import $ from 'jquery';
import './DragFoot.css'

type footProps = {
  xPosition: number;
  yPosition: number;
  onDrag: (x: number, y: number) => void;
  onDragStart: () => void;
  onDragStop: () => void;
}

const Foot = ({  xPosition, yPosition, onDrag, onDragStart, onDragStop }: footProps) => {  
  return (
    <Draggable
      bounds="parent"
      handle="#foot"
      onDrag={(e: DraggableEvent, data: DraggableData) => onDrag(data.x, data.y)}
      position={{x: xPosition, y: yPosition}}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      <div id="foot" style={window.innerHeight  < 700 ? { bottom: 0, top: 'unset' } : {}}>
        <img src="https://res.cloudinary.com/seimutants/image/upload/v1724376106/LickCoin/klqxhbqx1iq9fo6gtzts.webp" alt="" className="foot" style={window.innerWidth < 700 ? { rotate: '90deg' } : {}} />
        <div className="handle">
          <div className="mask"></div>
          <div className="grip"></div>
        </div>
      </div>
    </Draggable>
  );
};

type mouthProps = {
  xPosition: number;
  yPosition: number;
  dragging: boolean;
  counterStart: () => void;
  counterEnd: () => void;
}

const Mouth = ({ xPosition, yPosition, dragging, counterStart, counterEnd }: mouthProps) => {
  const tongueRef = useRef(null);
  const positionReference = window.innerWidth < 700 ? yPosition: xPosition

  useEffect(() => {
    if (dragging) {
      animateLick();
    } else {
      stopLick();
    }
  }, [dragging]);

  const animateLick = () => {
    if(!tongueRef.current) return;
    
    $(tongueRef.current)
      .animate({ left: '10%' }, 200)
      .animate({ left: '0' }, 200, () => {
        if (dragging) {
          animateLick();
        } else {
          if(!tongueRef.current) return;
          $(tongueRef.current).animate({ left: '0' }, 200);
        }
      });
  };

  const stopLick = () => {
    if(!tongueRef.current) return;
    $(tongueRef.current).stop(true).css({ left: '0' });
  };

  useEffect(() => {
    const footWidth = document.getElementById('foot')
    const mouthWidth = document.getElementById('mouth')
    
    if(!tongueRef.current || !footWidth || !mouthWidth) return;
    const width: number = window.innerWidth > 700 ? ((document.getElementById('foot-contain')?.offsetWidth) || 0) - ((document.getElementById('foot')?.offsetWidth) || 0) : ((document.getElementById('foot-contain')?.offsetHeight) || 0) - ((document.getElementById('foot')?.offsetHeight) || 0)
      
    // console.log(width, positionReference, width*0.1, width*0.2)
    const tongue = $(tongueRef.current);
    if (positionReference < -(width * 0.5)) {
      tongue.removeClass('licky lickylicky').addClass('lickylickylicky');
      counterStart()
    } else if (positionReference <= -(width * 0.15)) {
      tongue.removeClass('licky lickylickylicky').addClass('lickylicky');
    } else if(positionReference < -10) {
      tongue.removeClass('lickylicky lickylickylicky').addClass('licky');
    }else if(positionReference >= -10){
      tongue.removeClass('licky')
      tongue.removeClass('lickylicky')
      tongue.removeClass('lickylickylicky')
      stopLick()
      counterEnd()
    }
  }, [positionReference]);

  return (
    <div style={(window.innerWidth < window.innerHeight) && window.innerHeight < 800 ? window.innerWidth < 700 ? { zoom: `${((window.innerWidth - 300)*0.27777777777) + 100}%` } : { zoom: `${((window.innerHeight - 500)*0.2) + 100}%` } : (window.innerWidth > window.innerHeight) && window.innerWidth > 700 ? { zoom: `${((window.innerWidth - 500)*0.08333333333) + 100}%` } : window.innerWidth < 700 ? { zoom: `${((window.innerWidth - 300)*0.27777777777) + 100}%` } : {}} id="mouth" className="mouth unready">
      <img src="https://res.cloudinary.com/seimutants/image/upload/v1724376105/LickCoin/kqwv9hvemcts44pqbydz.webp" alt="" className="front" />
      <img src="https://res.cloudinary.com/seimutants/image/upload/v1724376104/LickCoin/eoxontrgx9z98iaxfivc.webp" alt="" className="tongue" ref={tongueRef} />
      {/* <div ref={tongueRef} className="tongue"></div> */}
      <img src="https://res.cloudinary.com/seimutants/image/upload/v1724376105/LickCoin/iemzeubiqbc6ygmoynbh.webp" alt="" className="back" />
    </div>
    
  );
};

type props = {
  setShowScreen: (x: boolean) => void;
  setShowWebsite: (x: boolean) => void;
}

const DragFoot = ({ setShowScreen, setShowWebsite }: props) => {
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [count, setCount] = useState(0)
  const [timer, setTimer] = useState<string | number | NodeJS.Timeout | undefined | null>(null)
  const [interval, setIntervalFun] = useState<string | number | NodeJS.Timeout | undefined | null>(null)

  const handleDrag = (x: number, y: number) => {
    if(window.innerWidth < 500) {
      setXPosition(Math.min(x, -50))
    }else {
      setXPosition(x);
    }
    setYPosition(y);

    // console.log(x, y);

    setDragging(true)
  };

  const handleDragStart = () => {
    $('#mouth').removeClass('unready').addClass('getready');
  };

  const handleDragStop = () => {
    counterEnd()
    setDragging(true)
    $('#mouth').addClass('unready').removeClass('getready');
    $('.tongue').removeClass('licky lickylicky lickylickylicky');

    try {
      const foot = document.getElementById('foot');
      if(foot){
        foot.style.transform = 'translate(0)'
        setXPosition(0)
        setYPosition(0)
      }
    } catch (error) {
      
    }
  };

  const counterStart = () => {
    if(timer === null || !timer){

      if(interval === null || !timer){
        const _int = setInterval(() => {
          setCount(count => count + 1)
        }, 1000)
        setIntervalFun(_int)
      }

      let _timer = setTimeout(() => {
        setShowScreen(true);
        setIntervalFun((_int: string | number | NodeJS.Timeout | undefined | null) => {
          if(_int){
            clearInterval(_int);
            return null;
          }
        })
        
        setTimeout(() => {
          setShowWebsite(true)
        }, 1300);
        setTimeout(() => {
          var timer = new Date().getTime()
          localStorage.setItem('showWebsiteTimer', timer.toString())
        }, 2300);
      }, 3200);
      
      setTimer(_timer);
    }
  }

  const counterEnd = () => {
    setTimer((_timer: string | number | NodeJS.Timeout | undefined | null) => {
      if(_timer){
        clearTimeout(_timer);
        return null;
      }
    })

    setIntervalFun((_int: string | number | NodeJS.Timeout | undefined | null) => {
      if(_int){
        clearInterval(_int);
        return null;
      }
    })

    setCount(prev => 0)
  }

  useEffect(() => {
    try {
      if(window.innerWidth < 700) {
        const footContainer = document.getElementById('foot-contain')
        const bottomText = document.getElementById('bottom_text')
        const mouthWidth = document.getElementById('mouth')

        if(footContainer && bottomText && mouthWidth){
          footContainer.style.height = `545px`;
          footContainer.style.right = '200px' 
          footContainer.style.top = -80 - (0.33333333333*(window.innerWidth - 300)) + 'px';
          footContainer.style.width = `${(document.getElementById('foot-contain')?.offsetHeight || 0)*0.75}px`;
          footContainer.style.position = 'absolute'
          if (window.innerWidth < 700 && window.innerWidth > 400) {
            footContainer.style.right = '150px' 
          }
        }
      }
    } catch (error) {
      console.log(error)    
    }
  }, [])

  return (
    <div className='flex items-center flex-col relative w-full h-full'
      // style={{
      style={window.innerWidth < 700 ? {
        transform: 'rotate(90deg)',
        transformOrigin: 'bottom left',
        position: 'absolute',
        top: '-100vw',
        height: '100vw',
        width: '100vh',
      } : window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}}
    >
      <div className="__container" style={{background: "#F883CA"}}>
        <div id="hotpackage">        
          <Mouth xPosition={xPosition} yPosition={yPosition} dragging={dragging} counterStart={counterStart} counterEnd={counterEnd} />
          
          <div style={(window.innerWidth < window.innerHeight) && window.innerHeight < 800 ? window.innerWidth < 700 ? { transform: 'rotate(-90deg)', zoom: `${((window.innerWidth - 500)*0.12179487179) + 100}%` } : { zoom: `${((window.innerHeight - 500)*0.2) + 100}%` } : (window.innerWidth > window.innerHeight) && window.innerWidth > 700 ? { zoom: `${((window.innerWidth - 500)*0.12179487179) + 100}%` } : window.innerWidth < 700 ? { transform: 'rotate(-90deg)', zoom: `${((window.innerWidth - 500)*0.12179487179) + 100}%` } : {}} id="foot-contain">
            <Foot
              onDrag={handleDrag}
              onDragStart={handleDragStart}
              onDragStop={handleDragStop}
              xPosition={xPosition}
              yPosition={yPosition}
            />
          </div>
        </div>
      </div>

      <div id='bottom_text' className='absolute bottom-0 left-1/2 -translate-x-1/2 p-2 pb-0 border-[2px] border-[#A30665] rounded-[28px] rounded-b-none w-max'>
        <div className='relative rounded-[28px] rounded-b-none bg-[#A30665] text-btm-color-white px-8 py-6 pb-3 text-center'>
          Drag the foot to start licking

          <div className='absolute w-full h-full top-0 left-0 rounded-[28px] rounded-b-none overflow-hidden'>
            <div className={`bg-[#FEBECC] text-btm-color-white ${count >= 1 ? 'px-8' : ''} py-6 pb-3 overflow-hidden whitespace-nowrap transition-all`}
              style={{
                color: "#A30665",
                width: `${count*33.5}%`,
                transition: 'all 0.3s ease-in-out'
              }}
            >
              Drag the foot to start licking
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DragFoot