import styles from './Footer.module.css'

const Footer = () => {
  return (
    <footer style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.footer}>
        <a href='#top' style={{ marginTop: '4px' }} className={styles.logo_wrpr}>
            <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/imhwtz50i1qfv4mlo258.svg'} alt="" className='w-[40px] h-[40px]' />
            <span className={styles.logo_text}>LICK COIN</span>
        </a>
        <div style={{ position: 'absolute', zIndex: 1000, left: '50%', translate: '-50%' }} className={styles.center_logo}>
            <img style={{ width: '160px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1724556801/ved83nhc47hgobobe9sm.webp'} alt="" />
        </div>
        <div className={styles.social_links}>
            <a href="https://x.com/LickCoinSolana" target="_blank" className={styles.btn_holder}>
                <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/uxpjc2cwdwbkopel2zh4.svg'} alt="" />
            </a>
            <a href="https://t.me/lickcoinsolana" target="_blank" className={styles.btn_holder}>
                <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/az8u12ydhvvin7kuxzov.svg'} alt="" />
            </a>
        </div>
    </footer>
  )
}

export default Footer