// import css files
import '../components/DraggableComponent/_css/BidenDrag.css'
import '../components/DraggableComponent/_css/ElonMuskDrag.css'
import '../components/DraggableComponent/_css/HomelanderDrag.css'
import '../components/DraggableComponent/_css/MessiDrag.css'
import '../components/DraggableComponent/_css/RonaldoDrag.css'
import '../components/DraggableComponent/_css/SnoopDogDrag.css'
import '../components/DraggableComponent/_css/TrumpDrag.css'

// import homelander from'../assets/elon_musk.png'
import biden from'../assets/biden.png'
import trump from'../assets/trump.png'
import elon_musk from'../assets/elon_musk.png'
import ronaldo from'../assets/ronaldo.png'
import messi from'../assets/messi.png'
import snoopDog from'../assets/snoop_dog.png'

// import milk from '../assets/milk.png'
import icecream from '../assets/ice-cream.png'
import flag from '../assets/usa-flag.png'
import money from '../assets/money.png'
import ispeed from '../assets/ispeed.png'
import cup from '../assets/trophy.png'
import meth from '../assets/meth.png'

export type slide = {
    background: string;
    link: string;
    props: string;
    tongue: string;
    mainImage: string;
    // component-props
    propName: string;
    propId: string;
    propFace: string;
    propDraggable: string;
    toungue_left: number;
    text: string;
}

export const exploreSlides: slide[] = [
    {
        background: 'linear-gradient(68deg, #80BCCF -1.59%, #3E94AE 100%)',
        link: 'homelander',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724712085/nez1d56ebcxwgpcw7wbs.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724712081/dazc5p3rb3uyexuwbnsp.webp',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696166/vlr98k30wphr5yvhqlwf.webp',
        propId: 'homelander',
        propName: 'milk',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1724712086/sjekfyerr9cqztbigfqi.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1724712085/nez1d56ebcxwgpcw7wbs.webp',
        toungue_left: window.innerWidth < 900 ? 48 : 130,
        text: "Got Milk?",
    },
    {
        background: 'linear-gradient(68deg, #F6B6C6 -1.59%, #DF89A0 100%)',
        link: 'biden',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724712080/ojbz9aslib92ec80xkyr.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724721096/a49thxbcu8zjlfiu0eww.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696164/zlydsjijrjvfbrqinhdw.webp',
        propId: 'biden',
        propName: 'icecream',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725549921/xdg49a35bddpi048zfgo.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1725549920/xiyx6niqfyt7muiwp4vm.webp',
        toungue_left: window.innerWidth < 900 ? 64 : 150,
        text: "It's Joever"
    },
    {
        background: 'linear-gradient(68deg, #FCD971 -1.59%, #F5B028 100%)',
        link: 'trump',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724716642/yzmrl5glisstyxghmuch.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724721210/djeq0x2pgqbuguiqip7c.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696165/juem2izqgksfh7it7g0w.webp',
        propId: 'trump',
        propName: 'flag',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725549921/eczorgtcthdymtknmcrq.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1725550063/whw1zqndo5rzlcm7okcu.webp',
        toungue_left: window.innerWidth < 900 ? 57 : 118,
        text: "MAGA"
    },
    {
        background: 'linear-gradient(68deg, #E25D75 -1.59%, #B31837 100%)',
        link: 'elon_musk',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724716654/toaiskxl4cwcewlfem3r.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724721625/ichyh70mdprtlsfy2lrd.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696165/gn0yvq2gw2tpgkfqloxx.webp',
        propId: 'elon_musk',
        propName: 'money',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725550056/ouozuldoz1duklkd7fdu.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1725550063/eumjjkeahsnponp7st5x.webp',
        toungue_left: window.innerWidth < 900 ? 72 : 100,
        text: "Free Speech"
    },
    {
        background: 'linear-gradient(68deg, #68E25D   -1.59%, #2EB318  100%)',
        link: 'ronaldo',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724716649/thw2kqcgaxrcgjyjakqh.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724722018/no5dupawssvkuphviwi8.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696169/hel0iiilvlsdjcn8ho5s.webp',
        propId: 'ronaldo',
        propName: 'speed',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725550059/lv7ljl33i6qdxghucbit.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1725549920/jqnvi6yhswpi9gdubajx.webp',
        toungue_left: window.innerWidth < 900 ? 48 : 140,
        text: "Better than Pessi"
    },
    {
        background: 'linear-gradient(68deg, #F8F8F8  -1.59%, #6B6B6B  100%)',
        link: 'messi',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724717600/uhbcmmq1grxx0zpd3hsj.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724722069/i8xdbhmdj7m9cymyzbfb.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696170/fsfnzmh3qanm7ogp7bia.webp',
        propId: 'messi',
        propName: 'cup',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725550058/nbx8ubqlslj8szailg4g.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1725550065/au7xfhmv2zij4rwi6x0b.webp',
        toungue_left: window.innerWidth < 900 ? 63 : 180,
        text: "FUCK SUIIII"
    },
    {
        background: 'linear-gradient(68deg, #D85DE2   -1.59%, #670B6F   100%)',
        link: 'snoop_dog',
        props: 'https://res.cloudinary.com/seimutants/image/upload/v1724717597/efckd04he72o37llk0vm.webp',
        tongue: 'https://res.cloudinary.com/seimutants/image/upload/v1724722106/vdeudv8poscj1lptgobf.png',
        mainImage: 'https://res.cloudinary.com/seimutants/image/upload/v1724696169/qr9xvfabbk05cbhhrkzt.webp',
        propId: 'snoop_dog',
        propName: 'meth',
        propFace: 'https://res.cloudinary.com/seimutants/image/upload/v1725549983/bxcvtwlky19j9hb5lnl6.webp',
        propDraggable: 'https://res.cloudinary.com/seimutants/image/upload/v1724717597/efckd04he72o37llk0vm.webp',
        toungue_left: window.innerWidth < 900 ? 55 : 168,
        text: "GIB WEED"
    },
]