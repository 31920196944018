import React, { useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import './DraggableComponent.css'
import trumpText from '../../assets/trump_text.png'

type draggableProps = {
    xPosition: number;
    yPosition: number;
    propId: string;
    propDraggable: string;
    onDrag: (x: number, y: number) => void;
    onDragStart: () => void;
    onDragStop: () => void;
}

const DraggableProp = ({ onDrag, onDragStart, onDragStop, xPosition, yPosition, propDraggable, propId }: draggableProps) => {
    return (
        <Draggable
            bounds="parent"
            handle="#draggable-prop-child"
            onDrag={(e: DraggableEvent, data: DraggableData) => onDrag(data.x, data.y)}
            position={{ x: xPosition, y: yPosition }}
            onStart={onDragStart}
            onStop={onDragStop}
        >
            <div id="draggable-prop-child" style={(window.innerWidth > 300 && window.innerWidth < 900) ? { zoom: `${((window.innerWidth - 300)*0.41666666666) + 100}%`, cursor: 'pointer' } : { cursor: 'pointer' }} className={`${propId}`}>
                <img src={propDraggable} alt="" className={`draggable-prop ${propId}`} style={window.innerWidth < 900 ? { rotate: '90deg' } : {}}  />
                <div className={`handle ${propId}`}>
                    <div className="mask"></div>
                    <div className="grip"></div>
                </div>
            </div>
        </Draggable>
    );
};

type mouthProps = {
    xPosition: number;
    yPosition: number;
    dragging: boolean;
    propId: string;
    propFace: string;
    toungue_left: number;
    tongue: string;
    counterStart: () => void;
    counterEnd: () => void;
}

const Mouth = ({ xPosition, yPosition, dragging, propId, propFace, toungue_left, tongue, counterStart, counterEnd }: mouthProps) => {
    const tongueRef = useRef(null);
    const positionReference = window.innerWidth < 900 ? yPosition: xPosition

    useEffect(() => {
        if (dragging) {
            animateLick();
        } else {
            stopLick();
        }
    }, [dragging]);

    const animateLick = () => {
        if (!tongueRef.current) return;

        $(tongueRef.current)
            .animate({ left: `${toungue_left}px` }, 200)
            .animate({ left: `${toungue_left - 10}px` }, 200, () => {
                if (dragging) {
                    animateLick();
                } else {
                    if (!tongueRef.current) return;
                    $(tongueRef.current).animate({ left: `${toungue_left}px` }, 200);
                }
            });
    };

    const stopLick = () => {
        if (!tongueRef.current) return;
        $(tongueRef.current).stop(true).css({ left: `${toungue_left}px` });
    };

    useEffect(() => {
        const propWidth = document.getElementById('draggable-prop-child')
        const mouthWidth = document.getElementById('mouth')
        if (!tongueRef.current || !propWidth || !mouthWidth) return;
        const width : any = window.innerWidth < 900 ? ((document.getElementById('draggable-prop-container')?.offsetHeight) || 0) : ((document.getElementById('draggable-prop-container')?.offsetWidth) || 0)
        const width2 : any = window.innerWidth < 900 ? ((document.getElementById('draggable-prop-child')?.offsetHeight) || 0) : ((document.getElementById('draggable-prop-child')?.offsetWidth) || 0)

        // console.log(width, width2, width - width2, window.innerWidth < 900)
        const tongue = $(tongueRef.current);
        if (window.innerWidth < 900) {
            if (positionReference < -((width - width2) * 0.25)) {
                tongue.removeClass(`licky_${propId} lickylicky_${propId}`).addClass(`lickylickylicky_${propId}`);
            } else if (positionReference <= -((width - width2) * 0.10)) {
                tongue.removeClass(`licky_${propId} lickylickylicky_${propId}`).addClass(`lickylicky_${propId}`);
                counterStart()
            } else if (positionReference < -(10)) {
                tongue.removeClass(`lickylicky_${propId} lickylickylicky_${propId}`).addClass(`licky_${propId}`);
            } else if (positionReference >= -(10)) {
                tongue.removeClass(`licky_${propId}`)
                tongue.removeClass(`lickylicky_${propId}`)
                tongue.removeClass(`lickylickylicky_${propId}`)
                stopLick()
                counterEnd()
            }
        } else {
            if (positionReference < -((width - width2) * 0.70)) {
                tongue.removeClass(`licky_${propId} lickylicky_${propId}`).addClass(`lickylickylicky_${propId}`);
            } else if (positionReference <= -((width - width2) * 0.30)) {
                tongue.removeClass(`licky_${propId} lickylickylicky_${propId}`).addClass(`lickylicky_${propId}`);
                counterStart()
            } else if (positionReference < -(10)) {
                tongue.removeClass(`lickylicky_${propId} lickylickylicky_${propId}`).addClass(`licky_${propId}`);
            } else if (positionReference >= -(10)) {
                tongue.removeClass(`licky_${propId}`)
                tongue.removeClass(`lickylicky_${propId}`)
                tongue.removeClass(`lickylickylicky_${propId}`)
                stopLick()
                counterEnd()
            }
        }
    }, [positionReference]);

    return (
        <div id="mouth" className={`mouth mouthits ${propId} unready`} style={(window.innerWidth > 300 && window.innerWidth < 900) ? { zoom: `${((window.innerWidth - 300)*0.41666666666) + 100}%` } : {}}>
            <div id="#relpto" className={window.innerWidth < 900 ? `${propId}` : ``}  style={{ position: 'relative' }}>
                <img src={propFace} alt="" className={`front ${propId}`} id='front' />
                <img src={tongue || "https://z.cdn.turner.com/adultswim/big/img/toe-licker/tongue.png"} alt="" className={`tongue ${propId}`} ref={tongueRef} />
            </div>
        </div>

    );
};

type props = {
    propFace: string;
    propName: string;
    propDraggable: string;
    propId: string;
    toungue_left: number;
    tongue: string;
    background: string;
    id: number;
    text: string;
}

const DraggableComponent = ({ propFace, propDraggable, propId, toungue_left, background, tongue, id, text }: props) => {
    const navigate = useNavigate();

    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [count, setCount] = useState(0)
    const [timer, setTimer] = useState<string | number | NodeJS.Timeout | undefined | null>(null)
    const [interval, setIntervalFun] = useState<string | number | NodeJS.Timeout | undefined | null>(null)

    const propWidth = document.getElementById('draggable-prop-child')
    const mouthWidth = document.getElementById('mouth')
    const width: number = window.innerWidth < 900
        ? (window.innerHeight - (propWidth?.clientWidth || 0) - (mouthWidth?.clientWidth || 0))
        : window.innerWidth > 1536
        ? (window.innerWidth - (propWidth?.clientWidth || 0) - (mouthWidth?.clientWidth || 0))*0.06510416666666667 + 100
        : (window.innerWidth - (propWidth?.clientWidth || 0) - (mouthWidth?.clientWidth || 0))

    const handleDrag = (x: number, y: number) => {
        setXPosition(x);
        setYPosition(y);

        setDragging(true)
    };

    const handleDragStart = () => {
        $('#mouth').removeClass('unready').addClass('getready');
    };

    const handleDragStop = () => {
        setDragging(true)
        $('#mouth').addClass('unready').removeClass('getready');
        $('.tongue').removeClass(`licky_${propId} lickylicky_${propId} lickylickylicky_${propId}`);

        try {
            const draggableProp = document.getElementById('draggable-prop-child');
            if (draggableProp) {
                draggableProp.style.transform = 'translate(0)'
                setXPosition(0)
                setYPosition(0)
            }
        } catch (error) {

        }
    };


    const counterStart = () => {
        if (timer === null || !timer) {

            if (interval === null || !timer) {
                const _int = setInterval(() => {
                    setCount(count => count + 1)
                }, 1000)
                setIntervalFun(_int)
            }

            let _timer = setTimeout(() => {
                // setShowScreen(true);
                setIntervalFun((_int: string | number | NodeJS.Timeout | undefined | null) => {
                    if (_int) {
                        clearInterval(_int);
                        return null;
                    }
                })

                setTimeout(() => {
                    navigate('/explore')
                }, 500);
            }, 5000);

            setTimer(_timer);
        }
    }

    const counterEnd = () => {
        setTimer((_timer: string | number | NodeJS.Timeout | undefined | null) => {
            if (_timer) {
                clearTimeout(_timer);
                return null;
            }
        })

        setIntervalFun((_int: string | number | NodeJS.Timeout | undefined | null) => {
            if (_int) {
                clearInterval(_int);
                return null;
            }
        })

        setCount(prev => 0)
    }

    useEffect(() => {
        try {
          if(window.innerWidth < 900) {
            const slidesWrapper = document.getElementById('drag-me-slides-container')
            const draggableContainer = document.querySelector(`#draggable-prop-container.${propId}`)
            const mouthWidth = document.querySelector(`#front.${propId}`)
    
            if(draggableContainer && mouthWidth && slidesWrapper){
                draggableContainer.setAttribute('style', `
                    width: ${(document.getElementById('hotpackage')?.offsetHeight)}px;
                    height: ${((document.getElementById('hotpackage')?.offsetWidth) || 0) - 80}px;
                    transform-origin: 50% ${(slidesWrapper.clientHeight / 2)}px;
                    right: -70px;
                    transform: rotate(-90deg);
                `);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }, [id, propId])

    const positionReference = window.innerWidth < 900 ? yPosition: xPosition
    return (
        <div style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : (window.innerWidth > 900 && window.innerWidth < 1200) ? { zoom: `${((window.innerWidth)*0.06510416666666667) + 10}%` } : {}} className='flex items-center flex-col relative w-full h-full'>
            <div id='component_wrpr' className="__container" style={{ background: background || "#F883CA" }}>
                <div id="hotpackage" style={window.innerWidth > 1536 ? { zoom: `125%` } : {}}>
                    <Mouth
                        xPosition={xPosition}
                        yPosition={yPosition}
                        dragging={dragging}
                        propId={propId}
                        propFace={propFace}
                        tongue={tongue}
                        toungue_left={toungue_left}
                        counterStart={counterStart}
                        counterEnd={counterEnd}
                    />

                    <div id="draggable-prop-container" style={window.innerWidth > 900 ? { height: `${(document.getElementById('hotpackage')?.offsetHeight)}px` } : {}} className={`${propId} `}>
                        <DraggableProp
                            onDrag={handleDrag}
                            onDragStart={handleDragStart}
                            onDragStop={handleDragStop}
                            xPosition={xPosition}
                            yPosition={yPosition}
                            propDraggable={propDraggable}
                            propId={propId}
                        />
                    </div>

                    {text && <>
                        {propId === 'trump'
                            ? <img src={trumpText} alt="" className={`desc-title ${propId}`} style={{
                                opacity: Math.abs(positionReference/width) || 0,
                                transition: 'opacity 0.3s ease-in-out'
                            }}/>
                            : <h1 className={`desc-title ${propId}`} style={{
                                opacity: Math.abs(positionReference/width) || 0,
                                transition: 'opacity 0.3s ease-in-out'
                            }}>{text}</h1>
                        }
                    </>}
                </div>
            </div>

            {/* <div className='this_btm_text absolute bottom-0 left-1/2 -translate-x-1/2 p-2 pb-0 border-[2px] border-[#A30665] rounded-[28px] rounded-b-none w-full xs:w-max'>
                <div className='relative rounded-[28px] rounded-b-none bg-[#A30665] text-btm-color-white px-8 py-6 pb-3 text-center'>
                    Drag the {propName} to start licking

                    <div className='absolute w-full h-full top-0 left-0 rounded-[28px] rounded-b-none overflow-hidden'>
                        <div className={`bg-[#FEBECC] text-btm-color-white ${count >= 1 ? 'px-8' : ''}  py-6 pb-3 overflow-hidden whitespace-nowrap transition-all`}
                            style={{
                                color: "#A30665",
                                width: `${count * 20}%`,
                                transition: 'all 0.3s ease-in-out'
                            }}
                        >
                            Drag the {propName} to start licking
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default React.memo(DraggableComponent)